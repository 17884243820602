import React from 'react';
import api from '../services/api';
import queryString from 'query-string';

import { Container, Row, Col } from '../components/react-grid';
import Layout from "../components/layout";
import SEO from "../components/seo";
import Pane from '../components/Pane';
import Hero from '../components/Hero';
import Button from '../components/Button';

import css from './contact-us.module.scss';
import cssOg from '../components/OverlayBlock.module.scss';
import cssBtn from '../components/Button.module.scss';
import wayeLogo from '../images/waye-logo-white-medium.png';
import heroImage from '../images/contact-us-hero-image.jpg';
import iconChevronUp from '../images/icon-chevron-up.svg';
import iconChevronDown from '../images/icon-chevron-down-white.svg';

const emailReg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const IAMS = [
  "interested in covering WAYE via media/press",
  "a brand/organization looking to host a WAYE Talk",
  "an industry expert interested in joining the WAYE community or speaking at a WAYE Talk",
  "interested in learning more about the WAYE Young Leaders Board",
  "interested in featuring Sinead at our next event or covering her story via press/media",
  "interested in learning about the WAYE Future of Work Certificate",
  "interested in learning about the custom WAYE WAYE Workshop",
  "Other"
];

class ContactUs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showIams: false,
      currentIndex: 0,
      iam: '',

      name: '',
      email: '',
      media_outlet: '',
      message: ''
    };
  }

  componentDidMount() {
    let defaultIam = IAMS[0];

    const { location } = this.props;
    if (location.search) {
      const qs = queryString.parse(location.search);
      if (qs.inquire && qs.inquire === 'sinead') {
        defaultIam = IAMS[4];
      }
    }

    this.setState({iam: defaultIam, currentIndex: 0, showIams: false});
  }


  handleClick(i, iam, evt) {
    evt.preventDefault();

    this.setState({currentIndex: i, iam: iam, showIams: false});
  }

  handleSubmit(evt) {
    evt.preventDefault();

    if (!this.isSubmittable()) {
      return;
    }

    const { name, email, media_outlet, message, iam } = this.state;
    const subject = (iam === 'Other') ? iam : `I am ${iam}`;

    fetch(`${api.host}/messages`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify({
        type: 'Contact Us',
        subject: subject,
        name: name,
        email: email,
        media_outlet: media_outlet,
        message: message
      })
    })
      .then(response => response.json())
      .then((data) => {
        if (data.statusCode > 400) {
          // TODO handle with better error message and UI
          alert('We ran into an problem processing your request. ' +
            'Please check your email and try again.');
        } else {
          alert("Thank you for contacting WAYE Talks.");

          this.setState({
            // iam: '',
            name: '', 
            email: '',
            media_outlet: '',
            message: '',
            showIams: false,
          });
        }
      })
      .catch((err) => {
        console.log('[error]', err); // TODO handle
      });
  }

  handleFieldChange(evt) {
    const { name, value } = evt.target;

    this.setState({[name]: value});
  }

  validEmail() {
    const { email } = this.state;
    return emailReg.test(String(email).toLowerCase());
  }

  isSubmittable() {
    const { name, email, message } = this.state;
    return !!name 
      && this.validEmail()
      && !!message;
  }

  toggleIams(evt) {
    evt.preventDefault();

    this.setState({showIams: !this.state.showIams});
  }

  render() {
    return (
      <Layout name="contact-us">
        <SEO title="Contact Us" keywords={[`WAYE`, `WAYE Talks`]} />

        <Pane name="landing" className={css.paneLanding}>
          <Hero 
            name="contact-us"
            h1="Contact"
            logo={wayeLogo}
            logo_alt='WAYE'
            img_src={heroImage}
            tagline="Use the arrows to select what you are looking for below."
          />
        </Pane>

        <Pane name="contact-form" style={{overflow: 'hidden'}}>
          <Container>
            <form action="#" className={css.iamForm}>
              <Row>
                <Col xs="12" sm="6" nogutter>
                  <div className={`${css.iamForm__input} ${css.iamForm__inputSelect}`}>
                    <select name="iam"
                      value={this.state.iam}
                      onChange={this.handleFieldChange.bind(this)}>
                      <optgroup label="">
                        {IAMS.map((iam, i) => (
                        <option key={i} value={iam}>
                          {iam !== 'Other' && 'I am '}
                          {iam}
                        </option>
                        ))}
                      </optgroup>
                    </select>
                  </div>
                  <div className={css.iamForm__input}>
                    <input type="text" 
                      name="name" 
                      placeholder="Full name"
                      onChange={this.handleFieldChange.bind(this)} />
                  </div>
                  <div className={css.iamForm__input}>
                    <input type="text" 
                      name="email" 
                      placeholder="Email"
                      onChange={this.handleFieldChange.bind(this)} />
                  </div>
                  <div className={css.iamForm__input}>
                    <input type="text" 
                      name="media_outlet" 
                      placeholder="Media Outlet (if applicable)"
                      onChange={this.handleFieldChange.bind(this)} />
                  </div>
                  <div className={css.iamForm__input}>
                    <textarea name="message" 
                      placeholder="Your message here..."
                      onChange={this.handleFieldChange.bind(this)}></textarea>
                  </div>
                </Col>
                <Col xs="12" sm="5" sm-offset="1" sm-extend nogutter className={css.iamForm__blockText}>
                  <div className={css.blockText__text}>
                    <h1 onClick={this.toggleIams.bind(this)}>
                      {this.state.iam !== 'Other' && 'I am '}
                      {this.state.iam}
                    </h1>
                    <ul className={css.iamSelection} style={{display: this.state.showIams ? 'block' : 'none'}}>
                      {IAMS.map((iam, i) => (
                      <li key={i} 
                        className={css.iamSelection__item}
                        data-current={i === this.state.currentIndex}>

                        <a href="#" 
                          onClick={this.handleClick.bind(this, i, iam)}>

                          {iam !== 'Other' && 'I am '}
                          {iam}
                        </a>
                      </li>
                      ))}
                    </ul>
                    <a href="#" 
                      className={css.iamSelection__chevron}
                      onClick={this.toggleIams.bind(this)}>
                      <img src={iconChevronDown} alt="" />
                    </a>
                  </div>

                  <Row relative className={css.blockText__action}>
                    <Col xs="10" xs-offset="1" sm="2" sm-offset="0" nogutter>
                      <a href="#"
                        data-clickable={this.isSubmittable()}
                        onClick={this.handleSubmit.bind(this)}
                        className={`${cssBtn.button} ${css.buttonContactForm}`}>

                        Submit
                      </a>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </form>
          </Container>
        </Pane>
      </Layout>
    );
  }
}

/**
 * expose
 */

export default ContactUs;